@import url("https://use.typekit.net/exs4bey.css");
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: #1a1a1a;
}

#root {
    height: 100%;
    width: 100%;
    overflow: auto;
    background: #1a1a1a;
}

.content {
    background: #1a1a1a;
    color: #fcfcfc;
    font-size: 1.3rem;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-family: "sofia-pro", sans-serif;
}

.text {
    padding: 2rem;
}

.loader {
    display: block;
    width: 100%;
    max-width: 32px;
    margin: 0 auto 10px auto;
}

@font-face {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: sofia-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
}